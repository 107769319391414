import { IconButton40, IconNames16, Intent, Menu, MenuDivider, MenuItem, Popover } from '@pinpointhq/thumbtack';
import * as React from 'react';
import { Contact } from '../../../../../../javascript/models';
import { createToast } from '../../../../../FlashToaster';
import humanize from '../../../../../shared/utilities/humanize';

export function columns(handleEdit, handleDelete, handleUpdateActive, fetchData) {
  return [
    {
      accessor: 'name',
      Header: 'Name',
    },
    {
      accessor: 'email',
      Header: 'Email',
    },
    {
      Cell: ({ original }: { original: Contact }) => {
        const { primary, secondary } = original;

        if (primary) return <div>Primary</div>;
        if (secondary) return <div>Secondary</div>;
        return <div>Other</div>;
      },
      Header: 'Type',
      sortable: false,
    },
    {
      Cell: ({ original }: { original: Contact }) => {
        return <div>{humanize(original.status)}</div>;
      },
      Header: 'Status',
      sortable: false,
    },
    {
      Cell: ({ original }: { original: Contact }) => {
        return <div>{original.subscribed ? 'Yes' : 'No'}</div>;
      },
      Header: 'Org Newsletter',
      sortable: false,
    },
    {
      Cell: ({ original }: { original: Contact }) => {
        return <div>{original.foundationNewsletter ? 'Yes' : 'No'}</div>;
      },
      Header: '10KIF Newsletter',
      sortable: false,
    },
    {
      Cell: ({ original }: { original: Contact }) => {
        const handleEditClick = () => handleEdit(original);
        const handleDeleteClick = () => handleDelete(original);
        const handleActiveClick = () => handleUpdateActive(original);
        const handleToggleSubscribe = () => {
          original.subscribed = !original.subscribed;
          original.save().then((success) => {
            if (success) {
              createToast({ type: 'success', text: 'Successfully updated contact' });
              fetchData();
            } else {
              createToast({ type: 'error', text: 'Unable to update contact' });
            }
          });
        };

        const handleToggleFoundationSubscribe = () => {
          original.foundationNewsletter = !original.foundationNewsletter;
          original.save().then((success) => {
            if (success) {
              createToast({ type: 'success', text: 'Successfully updated contact' });
              fetchData();
            } else {
              createToast({ type: 'error', text: 'Unable to update contact' });
            }
          });
        };

        const handlePrimaryClick = () => {
          original.markAsPrimary = true;
          original.save().then((success) => {
            if (success) {
              createToast({ type: 'success', text: 'Successfully updated contact' });
              fetchData();
            } else {
              createToast({ type: 'error', text: 'Unable to update contact' });
            }
          });
        };

        const handleSecondaryClick = () => {
          original.markAsSecondary = true;
          original.save().then((success) => {
            if (success) {
              createToast({ type: 'success', text: 'Successfully updated contact' });
              fetchData();
            } else {
              createToast({ type: 'error', text: 'Unable to update contact' });
            }
          });
        };

        return (
          <div>
            <Popover>
              <IconButton40 icon={IconNames16.OVERFLOW} />
              <Menu>
                <MenuItem text="Edit" onClick={handleEditClick} />
                {!original.primary && (
                  <MenuItem
                    text={`Mark as ${original.status === 'active' ? 'inactive' : 'active'}`}
                    onClick={handleActiveClick}
                  />
                )}
                <MenuItem
                  text={original.subscribed ? 'Unsubscribe from org newsletter' : 'Subscribe to org newsletter'}
                  onClick={handleToggleSubscribe}
                />
                <MenuItem
                  text={
                    original.foundationNewsletter
                      ? 'Unsubscribe from 10KIF newsletter'
                      : 'Subscribe to 10KIF newsletter'
                  }
                  onClick={handleToggleFoundationSubscribe}
                />
                {!original.primary && <MenuItem text="Mark as primary" onClick={handlePrimaryClick} />}
                {!original.secondary && !original.primary && (
                  <MenuItem text="Mark as secondary" onClick={handleSecondaryClick} />
                )}
                <MenuDivider />
                <MenuItem text="Delete" intent={Intent.DANGER} onClick={handleDeleteClick} />
              </Menu>
            </Popover>
          </div>
        );
      },
      Header: '',
      sortable: false,
      width: 80,
    },
  ];
}
