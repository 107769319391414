import {
  ActionBar,
  Alerter,
  Body,
  Button,
  Card,
  CardLevel,
  Divider,
  Drawer,
  IconButton40,
  IconNames16,
  Intent,
  Menu,
  MenuItem,
  PaddingLevel,
  Popover,
  ProgressBar,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import ReactTable from 'react-table';
import { Application } from '../../../../../../../javascript/models';
import { createToast } from '../../../../../../FlashToaster';
import { Checkbox } from '../../../../../../shared/Checkbox';
import { DrawerHeader } from '../../../../../../shared/DrawerHeader';
import { HeaderCheckbox } from '../../../../../../shared/HeaderCheckbox';
import { promiseAllWithProgress } from '../../../../../../shared/promiseAllWithProgress';
import humanize from '../../../../../../shared/utilities/humanize';
import { useSpraypaintTableNamedParams } from '../../../../../shared/hooks/useSpraypaintTable';
import NewPaginationComponent from '../../../../../shared/NewPaginationComponent';

export function ApplicationDrawer({ handleClose, job }) {
  const [selection, setSelection] = React.useState<Application[]>([]);
  const { tableProps, fetchData } = useSpraypaintTableNamedParams({
    initialScope: Application.where({ job_id: job.id }).includes(['jobSeeker']),
    searchIdentifier: 'applications',
  });

  const paginationProps = React.useCallback(() => tableProps, [tableProps]);

  const columns = [
    {
      Cell: ({ original }) => <Checkbox resource={original} data={tableProps.data} {...{ selection, setSelection }} />,
      Header: () => <HeaderCheckbox selection={selection} setSelection={setSelection} data={tableProps.data} />,
      accessor: 'id',
      className: '-checkbox',
      headerClassName: '-checkbox',
      // Max width is the checkbox size (16px) + 8px of padding either side. Needs to be 40 due to IE.
      maxWidth: 40,
      sortable: false,
    },
    {
      Header: 'Name',
      accessor: 'jobSeeker.fullName',
    },
    {
      Header: 'Email',
      accessor: 'jobSeeker.email',
    },
    {
      Cell: (row: { original: Application }) => {
        return humanize(row.original.status);
      },
      Header: 'Status',
      accessor: 'status',
    },
    {
      Cell: (row: { original: Application }) => {
        const handleDelete = () => {
          Alerter.create({
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes',
            onConfirm: () => {
              row.original.destroy().then((success) => {
                if (success) {
                  createToast({ type: 'success', text: 'Application successfully deleted' });
                  fetchData();
                }
              });
            },
            text:
              'Are you sure you want to delete this application? This action cannot be undone, and will be reflected in the companies Pinpoint instance',
          });
        };

        return (
          <Popover>
            <IconButton40 icon={IconNames16.OVERFLOW} />
            <Menu>
              <MenuItem intent={Intent.DANGER} text="Delete" onClick={handleDelete} />
            </Menu>
          </Popover>
        );
      },
      Header: '',
      accessor: 'id',
      sortable: false,
      width: 80,
    },
  ];

  const onCancel = () => {
    setSelection([]);
  };

  return (
    <Drawer isOpen={true} onClose={handleClose} maxWidth={1000}>
      <DrawerHeader title="Applications" handleClose={handleClose} />
      <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.REGULAR} style={{ overflowY: 'scroll' }}>
        {selection.length > 0 && (
          <div className="mar-b-1">
            <ActionBar handleCancel={onCancel} selection={selection} fetchData={fetchData} />
          </div>
        )}
        <ReactTable
          {...tableProps}
          columns={columns}
          PaginationComponent={NewPaginationComponent}
          getPaginationProps={paginationProps}
          className="-unthemed"
          minRows={1}
        />
      </Card>
    </Drawer>
  );
}

function ActionBar({ handleCancel, fetchData, selection }) {
  const handleClick = () => {
    Alerter.create({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      onConfirm: () => {
        promiseAllWithProgress({
          callback: (progress) => displayProgressToast(progress),
          promises: selection.map((application) => deleteApplication(application)),
        }).then(() => {
          displaySuccessToast();
          handleCancel();
          fetchData();
        });
      },
      text: `Are you sure you want to delete the selected applications?`,
    });
  };

  function displaySuccessToast() {
    setTimeout(() => {
      createToast({
        key: toastKey,
        text: 'Successfully updated requests',
        type: 'success',
      });
    }, 1000);
  }

  async function deleteApplication(application) {
    await application.destroy();
  }

  const toastKey = 'deleteApplications';

  function displayProgressToast(progress) {
    const toastContent = (
      <>
        Deleting applications {progress.complete} of {progress.total}
        <div style={{ marginTop: '4px' }}>
          <ProgressBar value={progress.percentage / 100} intent={Intent.NONE} />
        </div>
      </>
    );

    createToast({
      isLoading: true,
      key: toastKey,
      text: toastContent,
    });
  }

  const defaultStyles = { padding: '4px 4px 4px 28px' };
  return (
    <Card elevated={true} level={CardLevel.FILLED} padded={false} style={{ ...defaultStyles }}>
      <div className="frow frow--items-center frow--justify-between">
        <div style={{ flex: '1 0 0%' }}>
          <div className="frow frow--items-center">
            <Body className="mar-r-2">{selection.length} applications selected</Body>
            <Button
              minimal={true}
              icon={IconNames16.DELETE}
              intent={Intent.DANGER}
              onClick={handleClick}
              text="Delete"
            />
          </div>
        </div>
        <div>
          <div className="frow frow--items-center">
            <ItemDivider />
            <Button icon={IconNames16.CLOSE} onClick={handleCancel} text="" minimal={true} />
          </div>
        </div>
      </div>
    </Card>
  );
}

function ItemDivider() {
  return <Divider muted={true} className="mar-h-1" style={{ height: '24px' }} />;
}
